import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { BnClient } from './components/Client'
import './index.css'

ReactDOM.render(
  <BrowserRouter>
    { <BnClient /> }
  </BrowserRouter>,
  document.getElementById('root')
);


