import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { BnButtonSeparator } from '../Button'
import { joinWith } from '../../classes/Util.js'
import './index.css'

export class BnFormFieldSeparator extends Component {
  render() {
    return <div className='bnFormFieldSeparator'/>
  }
}

export const BnFormButtons = props => {
  return <div className='bnFormButton'>
    <div className='bnFormButtons'>
    {joinWith(props.children, () => <BnButtonSeparator/>)}
     </div>
     </div>
}

export const BnFormFields = props => {
  return <div className='bnFormFields'>
    {props.children}
    </div>
}

export class BnForm extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    return <div className='bnForm'>
      {this.props.children}
      </div>
  }
}
