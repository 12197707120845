import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import BxSpinner from '../../assets/Spinners/SpinnerShape.svg'
import BxSearch from '../../assets/Icons/bxSearch.svg'
import BxCross from '../../assets/Icons/bxCross.svg'
import { isDesktop } from '../../classes/Platform.js'
import BxRight from '../../assets/Icons/bxRight.svg'
import CurrencyInput from '../CurrencyInput'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import owasp from "owasp-password-strength-test";
import Cleave from 'cleave.js/react'
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us'
import Util from 'cleave.js/src/utils/Util';
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import './index.css'

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

// hacks for another npm package fail
class Cleave1 extends Cleave {
  constructor(props) {
    super(props);
    const cls = Cleave1;
    const self = this;
    self.onFocus = event => {
      var owner = this, pps = owner.properties;
      if (pps.prefix && pps.noImmediatePrefix && !event.target.value) {
        owner.onInput(pps.prefix);
      }
      
      //event.target.rawValue = owner.getRawValue();
      event.target.value = pps.result;
      owner.registeredEvents.onFocus(event);
      Util.fixPrefixCursor(owner.element, pps.prefix, pps.delimiter, pps.delimiters);
    }
    self.onChange = event => {
      var owner = this,
          pps = owner.properties;
      owner.onInput(event.target.value);
      //event.target.rawValue = owner.getRawValue();
      event.target.value = pps.result;
      owner.registeredEvents.onChange(event);
    }
    self.onBlur = event => {
      var owner = this, pps = owner.properties;
      
      //event.target.rawValue = owner.getRawValue();
      event.target.value = pps.result;
      
      owner.registeredEvents.onBlur(event);
    }
    const prev = self.componentWillReceiveProps
  }

  /*
  componentDidMount() {
    super.componentDidMount()
    this.state.value = this.props.value
    this.forceUpdate()
  }
*/

}

// hacks for sorry npm fails
class MyCurrencyInput extends CurrencyInput {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    return;
  }

  setInput = input => {
    if (!this.theInput && input) {
      this.theInput = input;
      const f = input.setSelectionRange;
      input.setSelectionRange = (start, end) => document.activeElement == input ? f.apply(input, [start, end]) : null;
      input.onfocus = e => {
        input.setSelectionRange(1, 999)
      }
      if (isDesktop() && this.props.autoFocus) {
        input.focus()
      }
    }
  }

  render() {
    return <input ref={this.setInput} type={this.props.inputType} value={this.state.maskedValue} onChange={this.handleChange} onFocus={this.handleFocus} {...this.state.customProps}/>
  }
}

export const BnTextArea = props => {
  const { placeholder, onChange, form, name } = props
  const onChangeTextArea = e => onChange(name, e.target.value)
  const value = form[name]
  return <div className='bnTextArea'>
    <textarea value={value} placeholder={placeholder} onChange={onChangeTextArea}/>
    </div>
}

export const BnBoolInput = props => {
  const { label, form, name } = props
  const onChange = e => props.onChange(name, e.target.checked)
  return <div className='bnBoolInput'>
    <div className='bnBoolInputLabel'>{label}</div>
    <mobiscroll.Switch theme='ios' checked={!!form[name]} onChange={onChange}/>
    </div>
}

export const BnNegBoolInput = props => {
  const { label, form, name } = props
  const onChange = e => props.onChange(name, e.target.checked)
  return <div className='bnBoolInput bnNegInput'>
    <div className='bnBoolInputLabel'>{label}</div>
    <mobiscroll.Switch theme='ios' checked={!!form[name]} onChange={onChange}/>
    </div>
}

export class BnInputField extends Component {
  constructor (props) {
    super(props)
  }

  onChange = e => {
    if (this.props.onChange){ 
      this.props.onChange(this.props.name, e.target.value)
    }
  }

  setRef = x => {
    if (x) {
      if (this.props.autoComplete) {
        x.autocomplete = this.props.autoComplete
      }
      if (this.props.autoFocus && isDesktop()) {
        x.focus()
      }
    }
  }

  render() {
    const value = this.props.form[this.props.name]
    let className = 'bnInputField'
    if (this.props.formErr && this.props.formErr.field === this.props.name) {
      className += ' bnInputFieldErr'
    }
    if (this.props.type === 'currency') {
      const props = this.props
      return <div className='bnCurrencyInput'>
        <div className='bnCurrencyInputLabel'>{props.label}</div>
        <MyCurrencyInput autoFocus={this.props.autoFocus} allowEmpty={false} precision={0} value={value || '0'} prefix={'$'} currency='USD' className='bnCurrencyInputInput' onChangeEvent={(e, maskedvalue, floatvalue) => {
          const ev = {
            target: {
              value: floatvalue
            }
          }
          this.onChange(ev)
        }}                                                                                                                                                                             />
        </div>
    }
    if (this.props.type === 'tel') {
      const props = this.props
      const handlePhoneNumberInput = e => {
        props.onChange(props.name, e.target.value); 
      }
      const setRef = x => {
        this.setRef(x)
      }
      const value = this.props.form[this.props.name]
      return <div className={className}><Cleave1  htmlRef={setRef} autoFocus={isDesktop() && this.props.autoFocus} className={'bnInputFieldInput bnInputFieldPhoneInput'} placeholder={this.props.label} value={value} options={{phone: true, phoneRegionCode: "Us"}} onChange={handlePhoneNumberInput}/></div>
   }
    return <div className={className}><input className='bnInputFieldInput' ref={this.setRef} disabled={!this.props.onChange} placeholder={this.props.label} value={value} type={this.props.type} onChange={this.onChange}/>
      </div>
  }
}

export class BnInputFieldSeparator extends Component {
  constructor (props) {
    super(props)
  }

  render() {
    return <div className='bnInputFieldSeparator'/>
  }
}

export class BnSearchField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }
  searchId = 0
  onChange = async e => {
    const id = ++this.searchId
    this.setState({
      busy: id,
    })
    console.log("SEARCH", e.target.value)
    await this.props.search(e.target.value)
    if (this.state.busy === id) {
      this.setState({
        busy: null
      })
    }
  }

  setRef = x => {
    if (x) {
      if (this.props.autoFocus && isDesktop()) {
        x.focus()
      }
    }
  }
  
  render() {
    const value = this.props.searchTerm
    const clear = () => this.props.search('')
    let className = 'bnSearchFieldIcon'
    let icon = BxSearch
    if (this.state.busy) {
      className += ' bnBusy'
      icon = BxSpinner
    }
    return <div className={'bnSearchField'}>
      <div className={className}><ReactSVG src={icon}/></div>
      <div className='bnSearchFieldInput'><input ref={this.setRef} placeholder={this.props.label || 'Search'} value={value} onChange={this.onChange}/></div>
      <div className='bnSearchFieldIcon bnClickable' onClick={clear} style={value ? null : { visibility: 'hidden'}}><ReactSVG src={BxCross}/></div>
      </div>
  }
}

export const BnDateInput = props => {
  const { label, form, name, onChange, dateFormat, dateWheels, onInit, onClick } = props
  const value = form[name]
  const onInitDate = (e, i) => {
    onInit(i)
  }
  const onChangeDate = (e, instance) => {
    const val = instance.getVal()
    console.log(val)
    onChange(name, val)
  }
  const dateInput = <div key='dateInput' className='bnDateInput'><mobiscroll.Date  onInit={onInitDate} theme={'ios'} value={value} dateFormat={dateFormat || ' MMM D dd'} height={45} rows={5} onSet={onChangeDate}  dateWheels={dateWheels || 'MD dd'}><input tabIndex={-1}/></mobiscroll.Date></div>
  return  <div className={'bnPageButton'} onClick={onClick}>
    <div className='bnPageButtonLeft'>
    <div className='bnPageButtonLabel'>{label}</div>
    </div>
    <div className='bnPageButtonRight'>
    <div className='bnPageButtonCount'>{dateInput}</div>
    <div className='bnPageButtonIcon'><ReactSVG src={BxRight}/></div>
    </div>
    </div>
}
